<template>
  <div :class="['layout-main', { 'main-page': isMainPage, },]">
    <top-bar v-if="isMainPage" />

    <main>
      <router-view />

      <store-review v-if="$app.store.getter('app.needComment')" />
    </main>

    <app-bar />
  </div>
</template>

<script>
import StoreReview from './StoreReview'

export default {
  name: 'LayoutMain',
  components: { StoreReview, },
  data() {
    return {
      interval: null,
    }
  },
  computed: {
    isMainPage() {
      return $app.router.current().route.main
    },
  },
  watch: {
    $route() {
      if ($app.router.current().route.auth) {
        $app.auth.needAuth(true)
      }
    },
    '$store.state.auth.user'(value) {
      if ($app.auth.user().id) {
        $app.store.action('app.cartCount')
        if (this.interval) {
          this.loadSalesCount()
        } else {
          this.start()
        }
      } else {
        $app.store.mutation('app.salesCount', 0)
        clearInterval(this.interval)
        this.interval = null
      }
    },
  },
  created() {
    if ($app.router.current().route.auth) {
      $app.auth.needAuth(true)
    }
    if ($app.auth.user().id) {
      this.start()
      $app.store.action('app.cartCount')
    }
  },
  beforeDestroy() {
    $app.store.mutation('app.salesCount', 0)
    clearInterval(this.interval)
    this.interval = null
  },
  methods: {
    start() {
      this.loadSalesCount()
      $app.store.action('app.checkNeedComment')
      this.interval = setInterval(() => {
        this.loadSalesCount()
        $app.store.action('app.checkNeedComment')
      }, 1000 * 10)
    },
    loadSalesCount() {
      $api.my.salesCount().then((response) => {
        $app.store.mutation('app.salesCount', response.data.content.count)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-main {
  padding-top: calc(env(safe-area-inset-top) +  55px);

  & {
    padding-bottom: calc(env(safe-area-inset-bottom) +  100px);
  }

  main {
    position: relative;
  }
}
</style>
