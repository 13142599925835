<template>
  <n-modal class="store-review" :loading="$var('load')" @close="close">
    <div class="title">Оцените продавца</div>
    <div class="seller">{{ order.seller.title }}</div>

    <div class="stars" @mousedown="down = true" @click="selectStar">
      <n-icon v-for="i in 5" :key="i" :icon="i>$form.get('stars')?'star':'star-filled'"
              @mousedown="() => { down = true; hoverStar(i) }" @mouseenter="hoverStar(i)"></n-icon>
      <div v-if="$form.errors('stars')" class="error">Необходимо выбрать количество звезд</div>
    </div>

    <div class="sub black">Что Вам понравилось?</div>
    <textarea placeholder="Комментарий" :value="$form.get('content')" @input="(e) => $form.set('content', e.target.value)" />
    <n-button color="primary" @click="submit">Отправить</n-button>
  </n-modal>
</template>

<script>
export default {
  name: 'StoreReview',
  data() {
    return {
      down: false,
    }
  },
  computed: {
    order() {
      return $app.store.getter('app.needComment')
    },
  },
  created() {
    this.$form.init({
      orderId: this.order.id,
      targetId: this.order.seller.id,
      targetType: 'store',
      stars: 0,
      content: '',
    })
    this.$form.rules({
      stars: [ 'required', ],
    })
  },
  methods: {
    hoverStar(value) {
      if (this.down) {
        this.$form.set('stars', value)
      }
    },
    selectStar() {
      if (this.down) {
        this.down = false
      }
    },
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.app.reviews.create(this.$form.get()).then(() => {
          $app.store.mutation('app.needComment', null)
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
    close() {
      this.$var('load', true)
      $api.app.orders.view(this.order.id).then(() => {
        $app.store.mutation('app.needComment', null)
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.store-review {
  text-align: center;
  .title {
    font-size: .9em;
    opacity: .8;
  }
  .seller {
    margin: 5px 0 20px;
    font-size: 1.8em;
    font-weight: bold;
  }
  .stars {
    white-space: nowrap;
    margin: 10px 0 40px;
    .n-icon {
      margin-right: 10px;
      cursor: pointer;
    }
    .error {
      font-size: .8em;
      color: var(--danger);
      margin: 5px 0;
    }
  }

  .rating-wrap{
    margin-bottom: 10px;
  }

  textarea{
    background: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    min-height: 200px;
    margin: 10px 0 15px;
    padding: 10px;
    &::placeholder{
      font-size: 12px;
      color: #BABABA;
    }
  }
}
</style>
